.App {
  text-align: center;
}

.buttons {
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttons button {
  margin-right: 10px;
  margin-left: 10px;
}

.bathtub {
  width: 100px;
  height: 100px;
  border:4px solid #000;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.bathtub p{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
}

.water {
	background: #00e0ff;
	width: 100%;
	transition: all 0.3s;
}
